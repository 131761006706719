import 'slick-carousel/slick/slick.js';
import Cookie from 'js.cookie';

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    if (!Cookie.get('alert') && $('#modal').length || $('#modal').hasClass('no-cookie')) {
      $('#modal').show();
      Cookie.remove('alert');
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });
    
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

        //dropdown on click
   $('.banner nav li.menu-item-has-children > a').click(function(e) {
    e.preventDefault();
    $(this).parent('.banner nav li.menu-item-has-children').toggleClass('open');
    $(this).parent('.banner nav li.menu-item-has-children').siblings('.open').removeClass('open');
  });

  //close dropdown when clicking anywhere else
  $(document).on('click touch', function(e) {
    if( !$(e.target).hasClass('.banner nav li.menu-item-has-children') && !$(e.target).parents('.banner nav li.menu-item-has-children').length ) {
      $('.banner nav li.menu-item-has-children.open').removeClass('open');
    }
  });

  $('.sideNav li.menu-item-has-children > a').click(function(e){
    e.preventDefault();
    $(this).next('.sideNav .sub-menu').slideToggle();
    $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
  });

  //shortens long text and adds read more/less links.
  function addReadMoreLink(className, maxLength) {
    $('.' + className).each(function() {
      var text = $(this).text();
      if(text.length > maxLength) {
        var shortText = text.substr(0, maxLength);
        var longText = text.substr(maxLength);
        var html = shortText + '<a href="#" class="read-more"> ...Read More</a><span class="more-text">' + longText + '</span><a href="#" class="read-less"> Read Less</a>';
        $(this).html(html);
        $(this).find('.more-text').hide();
        $(this).find('.read-less').hide();
      }
    });
    $('.read-more').click(function(e) {
      e.preventDefault();
      $(this).hide();
      $(this).siblings('.more-text').show();
      $(this).siblings('.read-less').show();
    });
  
    $('.read-less').click(function(e) {
      e.preventDefault();
      $(this).hide();
      $(this).siblings('.more-text').hide();
      $(this).siblings('.read-more').show();
    });
  }

  //function call back (text class name, text max length)
  addReadMoreLink('read-more-short', 210);

      /* serves hero image variant based on breakpoint
    *  needs cloudflare cdn on div with .hero and .cloudflare classes
    */
      $(window).resize(function() {
        var screenSizes = [1600, 1440, 1200, 992, 768, 576];
        var imageSizes = ['/1920px', '/1600px', '/1440px', '/1200px', '/992px', '/768px', '/576px'];
        var styleAttr = $('.hero.cloudflare').attr('style');
        
        if (styleAttr) {
          for (var i = 0; i < screenSizes.length; i++) {
            if ($(window).width() < screenSizes[i]) {
              styleAttr = styleAttr.replace(imageSizes[i], imageSizes[i+1]);
            } else {
              styleAttr = styleAttr.replace(imageSizes[i+1], imageSizes[i]);
            }
          }
        
          $('.hero.cloudflare').attr('style', styleAttr);
        }
      }).trigger('resize');
  
      if ($('.testimonials-carousel').length) {
        $('.testimonials-carousel').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          appendArrows: '.testimonials-panel .slider-nav',
          appendDots: '.testimonials-panel .slider-nav',
          adaptiveHeight: false,
          arrows: true,
          dots: true,
          responsive: [
            {
              breakpoint: 1439,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              },
            },
          ],
        });
      }
   //add dynamic More link to testimonial text
   var testimonialClass = 'testimonial-quote'; //class that wraps testimonial text in html
  
   $('.' + testimonialClass).each(function() {
     var maxLength = 240;
     var text = $(this).text();
     if(text.length > maxLength) {
       var shortText = text.substr(0, maxLength);
       var longText = text.substr(maxLength);
       var html = shortText + '<a href="#" class="read-more">... More</a><span class="more-text">' + longText + '</span>';
       $(this).html(html);
       $(this).find('.more-text').hide();
     }
   });
     
   $('.slick-slide .read-more').click(function(e) {
     e.preventDefault();
     $(this).hide();
     $(this).siblings('.more-text').show();
     $(this).closest('.slick-slide').siblings().find('.more-text').hide();
     $(this).closest('.slick-slide').siblings().find('.read-more').show();
   });
     
   $('.slick-arrow, .slick-dots button').click(function(e) {
     e.preventDefault();
     $('.' + testimonialClass + ' .more-text').hide();
     $('.' + testimonialClass + ' .read-more').show();
   });
  
 //review us page
 $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
  localStorage.setItem('activeTab', $(e.target).attr('href'));
  });
  // Get the stored active tab from localStorage
  var activeTab = localStorage.getItem('activeTab');
  // If there is no stored active tab or it doesn't exist, set the first tab as active
  if (!activeTab || !$('#review-tab a[href="' + activeTab + '"]').length) {
    $('#review-tab a[data-toggle="tab"]').first().tab('show');
  } else {
    // If there is a stored active tab, show it
    $('#review-tab a[href="' + activeTab + '"]').tab('show');
  }
  },
};
